import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import styled from 'styled-components';
import SEO from '../components/SEO';
import Nav from '../components/Nav';
import Header from '../components/Header';

const PostGrid = styled.div`
  margin-top: 2rem;
  display: grid;
  gap: 0.5rem;
  grid-template-rows: auto auto auto auto auto auto auto;
  justify-items: center;

  .negrita {
    font-weight: bold;
  }

  .cursiva {
    font-style: italic;
  }

  h2 {
    background-color: var(--color_5);
    display: inline-block;
    padding: 3px 5px 5px 3px;
    text-align: center;
  }

  h3 {
    display: inline-block;
    background-color: transparent;
  }

  h4 {
    background-color: transparent;
  }

  blockquote {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  
  .gatsby-image-wrapper {
    margin-top: 3rem;
    max-height: 600px;
    max-width: 600px;
  }
  
  .container-texto-articulo {
    padding-top: 2rem;
    padding-bottom: 2rem;
    width: 70vw;
    @media (max-width: 800px) {
      width: 90vw;
      margin: 0 auto;
    }
  }

  .hand-drawn-divider-2 {
    margin-bottom: 2rem;
  }
`;

export default function TestPostPage({ data: { post, categorias } }) {

  var fecha_str = post.publishedAt.toString()
  var year = fecha_str.slice(0, 4);
  var month = fecha_str.slice(5, 7);
  var day = fecha_str.slice(8, 10);

  const serializers = {
    types: {
      undefined: props => (
        <p>{props.children[0].text}</p>
      ),

    }
  }

  return (
    <>
      <SEO title={post.title} image={post.mainImage?.asset?.fluid?.src} />
      <Header />
      <Nav categorias={categorias.nodes}/>
      <PostGrid>
        <h2>{post.title}</h2>
        <h3>Por {post.author.name}</h3>
        <h4 className="mark">{day}-{month}-{year}</h4>
        <div className="hand-drawn-divider-2"></div>
        <Img style={{width: "80%"}} fluid={post.mainImage.asset.fluid} />
        <div className="container-texto-articulo">
          {post.body.map((paragraph) => {
            switch (paragraph.style) {
              case 'normal':
                return (
                  //<p>{paragraph.children[0].text}</p>
                  <p key={(post.id)}>
                    {paragraph.children.map((partecita) => {
                      const id_partecita = post.id +"-" + Math.floor(Math.random() * 1000000);
                      return(
                        <span key={(id_partecita)} className={((partecita.marks.includes('strong')) ? 'negrita' : '') + ' ' + ((partecita.marks.includes('em')) ? 'cursiva' : '')}>{partecita.text}</span>
                      )
                    })}
                  </p>
                );
              case 'h1':
                return (
                  //<p>{paragraph.children[0].text}</p>
                  <h1 key={(post.id)}>
                    {paragraph.children.map((partecita) => {
                      const id_partecita = post.id +"-" + Math.floor(Math.random() * 1000000);
                      return(
                        <span key={(id_partecita)} className={((partecita.marks.includes('strong')) ? 'negrita' : '') + ' ' + ((partecita.marks.includes('em')) ? 'cursiva' : '')}>{partecita.text}</span>
                      )
                    })}
                  </h1>
                );
              case 'h2':
                return (
                  //<p>{paragraph.children[0].text}</p>
                  <h2 key={(post.id)}>
                    {paragraph.children.map((partecita) => {
                      const id_partecita = post.id +"-" + Math.floor(Math.random() * 1000000);
                      return(
                        <span key={(id_partecita)} className={((partecita.marks.includes('strong')) ? 'negrita' : '') + ' ' + ((partecita.marks.includes('em')) ? 'cursiva' : '')}>{partecita.text}</span>
                      )
                    })}
                  </h2>
                );
              case 'h3':
                return (
                  //<p>{paragraph.children[0].text}</p>
                  <h3 key={(post.id)}>
                    {paragraph.children.map((partecita) => {
                      const id_partecita = post.id +"-" + Math.floor(Math.random() * 1000000);
                      return(
                        <span key={(id_partecita)} className={((partecita.marks.includes('strong')) ? 'negrita' : '') + ' ' + ((partecita.marks.includes('em')) ? 'cursiva' : '')}>{partecita.text}</span>
                      )
                    })}
                  </h3>
                );
              case 'h4':
                return (
                  //<p>{paragraph.children[0].text}</p>
                  <h4 key={(post.id)}>
                    {paragraph.children.map((partecita) => {
                      const id_partecita = post.id +"-" + Math.floor(Math.random() * 1000000);
                      return(
                        <span key={(id_partecita)} className={((partecita.marks.includes('strong')) ? 'negrita' : '') + ' ' + ((partecita.marks.includes('em')) ? 'cursiva' : '')}>{partecita.text}</span>
                      )
                    })}
                  </h4>
                );
              case 'blockquote':
                return (
                  //<p>{paragraph.children[0].text}</p>
                  <blockquote key={(post.id)}>
                    {paragraph.children.map((partecita) => {
                      const id_partecita = post.id +"-" + Math.floor(Math.random() * 1000000);
                      return(
                        <span key={(id_partecita)} className={((partecita.marks.includes('strong')) ? 'negrita' : '') + ' ' + ((partecita.marks.includes('em')) ? 'cursiva' : '')}>{partecita.text}</span>
                      )
                    })}
                  </blockquote>
                );
              default:
                return (
                  //<p>{paragraph.children[0].text}</p>
                  <p key={(post.id)}>
                    {paragraph.children.map((partecita) => {
                      const id_partecita = post.id +"-" + Math.floor(Math.random() * 1000000);
                      return(
                        <span key={(id_partecita)} className={((partecita.marks.includes('strong')) ? 'negrita' : '') + ' ' + ((partecita.marks.includes('em')) ? 'cursiva' : '')}>{partecita.text}</span>
                      )
                    })}
                  </p>
                );
            }
          }
        )}
        </div>
        <div className="hand-drawn-divider-2"></div>
      </PostGrid>
      <button id="scroll-btn" className="sidebar-btns" title="Scroll to top"></button>
    </>
  );
}

// 1. Esto tiene que ser dinámico en base al slug

export const query = graphql`
  query ($slug: String!) {
    post: sanityPost(slug: {
      current: { eq: $slug } }) {
      id
      author {
        name
      }
      title
      mainImage {
        asset {
          fluid(maxWidth: 800) {
            ...GatsbySanityImageFluid
          }
        }
      }
      publishedAt
      body {
        children {
          text
          _type
          marks
        }
        style
      }
    }
    categorias: allSanityCategory {
      nodes {
        title
      }
    }
  }
`;